import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



import { VueJsonp } from 'vue-jsonp'

// Vue Plugin.
Vue.use(VueJsonp)




Vue.config.productionTip = false
import './permission'
import Print from './utils/print'
import print from 'print-js'
Vue.use(Print);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
