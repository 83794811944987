import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const MENU_STATE = 'MENU_STATE'
const CONSOLE_MENU_STATE = 'CONSOLE_MENU_STATE'
const ROUTE_STATE = 'ROUTER_STATE'
const True_Name = 'True_Name'
const auth = 'auth'
const SALEUSERID = 'SaleUserId'
const WWWUSERID = 'wwwUserId'
const ISSHOW = 'IsShow'
const LINEDDATA = 'LinedData'
const roleId = 'roleId'
const roleName = 'roleName'



// token
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

// 是否是第一次展示
export function getISSHOW() {
  return JSON.parse(localStorage.getItem(ISSHOW))
}

export function setISSHOW(isShow) {
  return localStorage.setItem(ISSHOW, JSON.stringify(isShow))
}

// 根据权限存储获取条线数组
export function getLinedData() {
  return JSON.parse(localStorage.getItem(LINEDDATA))
}

export function setLinedData(LinedData) {
  return localStorage.setItem(LINEDDATA, JSON.stringify(LinedData))
}


export function removeToken() {
  Cookies.remove(auth)
  Cookies.remove(True_Name)
  Cookies.remove(TokenKey)
  Cookies.remove(WWWUSERID)
  Cookies.remove(SALEUSERID)
  localStorage.removeItem(ISSHOW)

  Cookies.remove(roleId)
  Cookies.remove(roleName)
}

// 用户名称
export function getTrueName() {
  return Cookies.get(True_Name)
}

export function setTrueName(name) {
    return Cookies.set(True_Name, name)
}

export function getRoleId(name) {
    return Cookies.get(roleId, name)
}
export function setRoleId(name) {
    return Cookies.set(roleId, name)
}
export function getRoleName(name) {
    return Cookies.get(roleName, name)
}

export function setRoleName(name) {
    return Cookies.set(roleName, name)
}

// 营销人员ID
export function getSaleUserId() {
  return Cookies.get(SALEUSERID)
}

export function setSaleUserId(id) {
  return Cookies.set(SALEUSERID, id)
}
// wwwUserID
export function getWWWUserId() {
  return Cookies.get(WWWUSERID)
}

export function setWWWUserId(id) {
  return Cookies.set(WWWUSERID, id)
}
// menu 状态  -- 客户管理
let stateCookies = {
  // 保存状态
  setState(state) {
    Cookies.set(MENU_STATE, state)
  },
  // 获取状态
  getState() {
    return Cookies.get(MENU_STATE)
  },
  removeState(state) {
    Cookies.set(MENU_STATE)
  },
}

// menu 状态  --  管理控制台
let consoleStateCookies = {
  // 保存状态
  setState(state) {
    Cookies.set(CONSOLE_MENU_STATE, state)
  },
  // 获取状态
  getState() {
    return Cookies.get(CONSOLE_MENU_STATE)
  },
}

// 路由高亮状态
let routerCookies = {
  // 保存路由状态
  setRouterState(state) {
    Cookies.set(ROUTE_STATE, state)
  },
  // 获取路由状态
  getRouterState() {
    return Cookies.get(ROUTE_STATE)
  }
}

export { stateCookies, routerCookies, consoleStateCookies, }
