<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <el-aside :width="isCollapse ? '65px' : '208px'">
        <el-menu
          :router="true"
          :default-active="getActive"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#495060"
          text-color="#fff"
          active-text-color="#ffd04b"
          :collapse="isCollapse"
        >
          <div class="subtitle" @click="goHome">
            <img class="text-a" src="../assets/images/logo.png" />
            <h1 class="title">中台系统</h1>
          </div>
          <el-menu-item index="/t/a1" v-if="isMaster">
            <i class="el-icon-s-data"></i>
            <span slot="title">物流公司</span>
          </el-menu-item>
          <!--<el-menu-item index="/t/a2">
            <i class="el-icon-s-data"></i>
            <span slot="title">地址</span>
          </el-menu-item>-->
          <!-- <el-menu-item index="/t/a2">
            <i class="el-icon-s-data"></i>
            <span slot="title">地址</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="/t/a2">
            <i class="el-icon-s-data"></i>
            <span slot="title">地址</span>
          </el-menu-item> -->
          <el-submenu index="1" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>消息配置中心</span>
            </template>
            <el-menu-item index="/newsn">消息配置中心</el-menu-item>
            <!-- <el-menu-item index="/mess">群发消息</el-menu-item> -->
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>物流管理</span>
            </template>
            <!--<el-menu-item index="/odd">打印快递单</el-menu-item>-->
            <el-menu-item index="/shipments">物流发货</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>物流订单</span>
            </template>
            <el-menu-item index="/logorders">直发订单</el-menu-item>
            <el-menu-item index="/mcc" v-if="isMaster">商户订单</el-menu-item>
            <!-- <el-menu-item index="/mccdetail">查看包裹</el-menu-item> -->
          </el-submenu>
          <!-- <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>广告管理</span>
            </template>
            <el-menu-item index="/advertising">广告管理</el-menu-item>
            <el-menu-item index="/addbanner">新增</el-menu-item>
          </el-submenu> -->
          <el-submenu index="5" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>模块管理</span>
            </template>
            <el-menu-item index="/moudels">模块管理</el-menu-item>
            <!-- <el-menu-item index="/adddel">新增</el-menu-item> -->
          </el-submenu>
          <el-submenu index="6" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>品类管理</span>
            </template>
            <el-menu-item index="/classadmin">品类管理</el-menu-item>
          </el-submenu>
          <el-submenu index="7" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>支付管理</span>
            </template>
            <el-menu-item index="/paytype">支付方式</el-menu-item>
          </el-submenu>
          <!-- <el-submenu index="8">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>设置不可发货区域</span>
            </template>
            <el-menu-item index="/setarea">设置不可发货区域</el-menu-item>
          </el-submenu> -->
          <el-submenu index="9" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>取消订单配置</span>
            </template>
            <el-menu-item index="/logcof">取消原因配置</el-menu-item>
          </el-submenu>
          <el-submenu index="10" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>功能设置</span>
            </template>
            <el-menu-item index="/setkd">取件设置</el-menu-item>
            <el-menu-item index="/district">不可发货区域管理</el-menu-item>
            <el-menu-item index="/platform">平台管理</el-menu-item>
            <el-menu-item index="/cause">不可发货原因管理</el-menu-item>
            <el-menu-item index="/collect">物流揽收围栏</el-menu-item>
          </el-submenu>
          <el-submenu index="11" v-if="isMaster">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>邮寄白名单</span>
            </template>
            <el-menu-item index="/colls">邮寄白名单</el-menu-item>
          </el-submenu>
          <!-- <el-submenu index="10">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>设置不可发货地区</span>
            </template>
            <el-menu-item index="/nore">设置不可发货地区</el-menu-item>
          </el-submenu> -->
          <!-- <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>数据分析</span>
            </template>
            <el-menu-item index="/t/a3">商品销售</el-menu-item>
            <el-menu-item index="/first3">物流公司占比</el-menu-item>
            <el-menu-item index="/first3">发货统计</el-menu-item>
          </el-submenu> -->
          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>数据分析</span>
            </template>
            <el-menu-item index="/first3">导航二级菜单1</el-menu-item>
            <el-submenu index="1-1">
              <template slot="title">
                <span>导航二级菜单2</span>
              </template>
              <el-menu-item index="/first4">二级菜单2-1</el-menu-item>
              <el-menu-item index="/first5">二级菜单2-2</el-menu-item>
            </el-submenu>
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <el-container style="width: 100%,background:#F0F0F0;">
        <el-header>
          <div class="navbar">
            <!-- 展开/收缩 -->
            <div class="switchButton-container">
              <i
                :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
                style="font-size: 23px"
                @click="showCollapse"
              ></i>
              <!-- 面包屑导航 -->
              <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item
                    v-for="(item, index) in $route.meta"
                    :key="index"
                  >
                    <router-link v-if="item.path" :to="item.path">{{
                      item.name
                    }}</router-link>
                    <a v-else>{{ item.name }}</a>
                  </el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
            <div class="right-menu">
                <span class="userInfo">
                    <span>
                        <a class="nav-link" @click="showMes">
                            <el-badge :value="tipCount" class="item">
                                <i class="el-icon-message-solid"></i>
                            </el-badge>
                        </a>
                    </span>
                    <span>您好，{{ isMaster == true ? "超级管理员" : $store.getters.trueName   }} <span class="logout" @click="logOut">退出</span></span>
                </span>

               
                <el-dialog title="系统消息"
                           :visible.sync="isDialogNoticeModel"
                           append-to-body
                           width="1000px"
                           @close="noticeModelClose"
                           style="height: 80%">
                    <NoticeModel v-if="isDialogNoticeModel" />
                    <div slot="footer">
                        <el-button type="default"
                                   size="small"
                                   @click="isDialogNoticeModel = false">关闭</el-button>
                    </div>
                </el-dialog>
            </div>
          </div>
        </el-header>
        <el-main style="padding: 0px 20px">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { stateCookies, removeToken } from "../utils/auth";
import { GetMyMessageNotReadCount } from "@/api/tip";
import NoticeModel from "./components/NoticeModel";
export default {
  data() {
    return {
      isCollapse: false, //默认展开
      isMaster: this.$store.getters.roleId == 1,
      tipCount: 3,
      isDialogNoticeModel: false,
    };
  },
  components: {
    NoticeModel,
  },
  methods: {
    noticeModelClose() {
      this.isDialogNoticeModel = false;
      this.getMyMessageNotReadCount();
    },
    showCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    //退出登录
    logOut() {
      removeToken(); //清空token
      stateCookies.setState("false");
      // 跳转到登录页
      this.$router.push("/");
    },
    //回到主页
    goHome() {
      //alert("别动");
      window.location.href = "https://middle.lawxp.com/dashboard";
    },
    //显示提示信息列表界面
    showMes() {
      this.isDialogNoticeModel = true;
    },
    //得到我的未读数
    async getMyMessageNotReadCount() {
      let res = await GetMyMessageNotReadCount();
      if (res.code == 10000) {
        this.tipCount = res.data;
      }
    },
    
    //
  },
  computed: {
    getActive: function () {
      var path = this.$route.path;
      return path;
    },
  },
  mounted: function () {
    this.getMyMessageNotReadCount();
  },
};
</script>

<style scoped>
.wp {
  width: 100%;
}
.el-menu {
  height: 100%;
  background-color: #495060;
  border-right: none !important;
}
.subtitle {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
}
.title {
  display: inline-block;
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 50px;
  font-size: 16px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.text-a {
  width: 34px;
  height: 34px;
  vertical-align: middle;
  margin-right: 6px;
}
/* .fixed-header {
  display: flex;
  justify-content: space-between;
} */
.navbar {
  display: flex;
  justify-content: space-between;
  height: 50px;
  overflow: hidden;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
}
.right-menu {
  float: right;
  width: 22%;
  height: 100%;
  line-height: 50px;
  margin-right: 68px;
}
.logout {
  font-size: 14px;
  color: rgb(64, 158, 255);
  cursor: pointer;
  margin-left:20px;
}
.userInfo {
    float: right;
}

.switchButton-container {
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  float: left;
  padding: 16px 0px;
  cursor: pointer;
  transition: background 0.3s;
  margin-left: 10px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 208px;
  min-height: 400px;
}
.breadcrumb {
  padding: 4px 10px;
}
.el-header {
  padding: 0px;
}
.el-main {
  background: #ddd;
}
.nav-link {
  margin-right: 25px;
  line-height: 10px;
  .item {
  }

  .el-icon-message-solid {
    font-size: 22px;
    color: #409eff;
  }
}
</style>