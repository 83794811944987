import { loginApi, GetRole, GetUserInfoByToken } from '../../api/login'
import { getToken, setToken, setWWWUserId, getWWWUserId, removeToken, getTrueName, setTrueName, setSaleUserId, setRoleId, setRoleName, getRoleId, getRoleName } from '@/utils/auth'

const state = {
    accessToken: getToken(),  // 营销人员token
    saleUserId: '',  // 营销人员ID
    trueName: getTrueName(),  // 营销人员姓名
    wwwUserId: getWWWUserId(),  // wwwUserId
    roleId: getRoleId(),  // 角色id
    roleName: getRoleName(),  // 角色名称
    msg: ''
}

const mutations = {
    // 改变营销人员token
    HANDLE_ACCESSTOKEN(state, data) {
        state.accessToken = data
    },

    // 改变营销人员ID
    HANDLE_SALEUSERID(state, data) {
        state.saleUserId = data
        setSaleUserId(data)
    },

    // 改变营销人员姓名
    HANDLE_TRUENAME(state, data) {
        state.trueName = data
        console.log(state.trueName);
    },

    // 改变营销人员wwwUserId
    HANDLE_WWWUSERID(state, data) {
        state.wwwUserId = data
    },

    // 角色id
    HANDLE_ROLEID(state, data) {
        state.roleId = data
    },

    // 角色名称
    HANDLE_ROLENAME(state, data) {
        state.roleName = data
    },
    HANDLE_MSG(state, data) {
        state.msg = data;
    },
}

const actions = {
    // 根据用户名密码获取token
    async loginAct({ commit }, data) {
        let r = await loginApi(data)
        console.log(r)
        commit('HANDLE_MSG', r.msg)
        if (r.code == 10000) {
            localStorage.setItem('token', r.data.accessToken)
            setToken(r.data.accessToken)
            // console.log(setToken(r.data.accessToken))  // 请求成功之后吧token存到本地
            setWWWUserId(r.data.wwwUserId)
            setTrueName(r.data.trueName)

            setRoleId(r.data.roleId)
            setRoleName(r.data.roleName)

            commit('HANDLE_ACCESSTOKEN', r.data.accessToken)
            commit('HANDLE_SALEUSERID', r.data.saleUserId)
            commit('HANDLE_TRUENAME', r.data.trueName)
            commit('HANDLE_WWWUSERID', r.data.wwwUserId)
            commit('HANDLE_ROLEID', r.data.roleId)
            commit('HANDLE_ROLENAME', r.data.roleName)
        }
    },
    //直接附加token
    async loginActByToken({ commit }, data) {
        let r = await GetUserInfoByToken(data)
        console.log(r, 'r')
        let userid = r.data.wwwUserId
        console.log(userid, 'userid');
        let d = await GetRole({ userId: userid }, r.data.accessToken)
        console.log(d, 'd');
        var token = r.data.accessToken;
        let strings = token.split("."); //截取token，获取载体
        var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
        commit('HANDLE_MSG', "")
        setToken(token)  // 请求成功之后吧token存到本地
        // setWWWUserId(userinfo.UserId)
        //     commit('HANDLE_ACCESSTOKEN', data.token)
        //     commit('HANDLE_SALEUSERID', 0)
        //     commit('HANDLE_TRUENAME', userinfo.given_name)
        //     commit('HANDLE_WWWUSERID', userinfo.UserId)
        //     let role = await GetRole({ userId:userinfo.UserId})

        commit('HANDLE_TRUENAME', d.data[0].trueName)
        if (d.code == 10000) {
            setRoleId(d.data[0].roleId)
            setRoleName(d.data[0].roleName)
            setTrueName(d.data[0].trueName)
        }

    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
