import request from '../utils/request'
import baseURL from '../utils/config'

// 根据用户名和密码获取token
export function loginApi(data) {
    return request({
        url: baseURL.baseURL + 'api/Token/login',
        method: 'get',
        params:data
    })
}

// 获取用户角色
export function GetRole(data,token) {
    return request({
        url: baseURL.baseURL + 'api/Adm_User/GetRole',
        method: 'get',
        params: data,
        headers:{
            'Authorization': "Bearer " + token
        }
    })
}

//根据token获取用户信息
export function GetUserInfoByToken(params) {
    console.log(123)
    console.log(baseURL.baseURL+'api/Token/GetUserInfoByToken');
    return request({
        url: baseURL.baseURL + 'api/Token/GetUserInfoByToken',
        method: 'get',
        params
    })
}




  
//验证token
export function VerifyToken(params) {
    console.log(123)
    return request({
        url: baseURL.baseURL + 'api/Token/VerifyToken',
        method: 'get',
        params
    })
}
  