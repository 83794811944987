import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },

  {
    path: '/t',
    component: Layout,
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/LogisticsCompany/index.vue'),
        meta: [
          { name: "首页", }
        ]
      },
      {
        path: 'a1',
        name: 'a1',
        component: () => import('../views/LogisticsCompany/index.vue'),
        meta: [
          { name: "物流管理", path: '/t' },
          { name: "物流公司", }
        ]
      },
      {
        path: 'a2',
        name: 'a2',
        component: () => import('../views/Address/index.vue'),
        meta: [
          { name: "地址", path: '/t' },
          { name: "二级菜单2-2", }
        ]
      },
      {
        path: 'a3',
        name: 'a3',
        component: () => import('../views/DataAnalysis/CommoditySales.vue'),
        meta: [
          { name: "地址", path: '/t' },
          { name: "商品销售", }
        ]
      },
    ],
  },
  {
    path: '/message',
    component: Layout,
    children: [
      {
        path: '/newsn',
        name: 'newsn',
        component: () => import('../views/message/newsn.vue'),
        meta: [
          { name: "消息设置", path: '/message' },
          { name: "消息设置", }
        ]
      },
      {
        path: '/newset',
        name: 'newset',
        component: () => import('../views/message/newset.vue'),
        meta: [
          { name: "消息配置", path: '/newsn' },
          { name: "消息配置", }
        ]
      },
      {
        path: '/mess',
        name: 'mess',
        component: () => import('../views/message/mess.vue'),
        meta: [
          { name: "配置", path: '/message' },
          { name: "群发消息", }
        ]
      }
    ]
  },
  {
    path: '/prints',
    component: Layout,
    children: [
      {
        path: '/odd',
        name: 'odd',
        component: () => import('../views/prints/odd.vue'),
        meta: [
          { name: "打印快递单", path: '/prints' },
          { name: "打印快递单", }
        ]
      },
      {
        path: '/shipments',
        name: 'shipments',
        component: () => import('../views/prints/shipments.vue'),
        meta: [
          { name: "打印快递单", path: '/prints' },
          { name: "新增订单", }
        ]
      }
    ]
  },
  {
    path: '/logorder',
    component: Layout,
    children: [
      {
        path: '/logorders',
        name: 'logorders',
        component: () => import('../views/logorder/logorders.vue'),
        meta: [
          { name: "物流订单", path: '/logorder' },
          { name: "直发订单", }
        ]
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/logorder/detail.vue'),
        meta: [
          { name: "物流订单", path: '/logorder' },
          { name: "直发订单详情", }
        ]
      },
      {
        path: '/mcc',
        name: 'mcc',
        component: () => import('../views/logorder/mcc.vue'),
        meta: [
          { name: "物流订单", path: '/logorder' },
          { name: "商户订单", }
        ]
      },
      {
        path: '/mccdetail',
        name: 'mccdetail',
        component: () => import('../views/logorder/mccdetail.vue'),
        meta: [
          { name: "物流订单", path: '/logorder' },
          { name: "查看包裹", }
        ]
      }
    ]
  },
  {
    path: '/banner',
    component: Layout,
    children: [
      {
        path: '/advertising',
        name: 'advertising',
        component: () => import('../views/banner/advertising.vue'),
        meta: [
          { name: "广告管理", path: '/advertising' },
          { name: "广告管理", }
        ]
      },
      {
        path: '/addbanner',
        name: 'addbanner',
        component: () => import('../views/banner/addbanner.vue'),
        meta: [
          { name: "广告管理", path: '/advertising' },
          { name: "新增广告", }
        ]
      }
    ]
  },
  {
    path: '/moudel',
    component: Layout,
    children: [
      {
        path: '/moudels',
        name: 'moudels',
        component: () => import('../views/moudel/moudels.vue'),
        meta: [
          { name: "模块管理", path: '/moudels' },
          { name: "模块管理", }
        ]
      },
      {
        path: '/adddel',
        name: 'adddel',
        component: () => import('../views/moudel/adddel.vue'),
        meta: [
          { name: "模块管理", path: '/moudels' },
          { name: "新增", }
        ]
      },
      {
        path: '/compile',
        name: 'compile',
        component: () => import('../views/moudel/compile.vue'),
        meta: [
          { name: "模块管理", path: '/moudels' },
          { name: "新增或编辑", }
        ]
      }
    ]
  },
  {
    path: '/classlei',
    component: Layout,
    children: [
      {
        path: '/classadmin',
        name: 'classadmin',
        component: () => import('../views/classlei/classadmin.vue'),
        meta: [
          { name: "品类管理", path: '/classlei' },
          { name: "品类管理", }
        ]
      }
    ]
  },
  {
    path: '/pay',
    component: Layout,
    children: [
      {
        path: '/paytype',
        name: 'paytype',
        component: () => import('../views/pay/paytype.vue'),
        meta: [
          { name: "支付管理", path: '/pay' },
          { name: "支付方式", }
        ]
      }
    ]
  },
  {
    path: '/area',
    component: Layout,
    children: [
      {
        path: '/setarea',
        name: 'setarea',
        component: () => import('../views/area/setarea.vue'),
        meta: [
          { name: "设置不可发货区域", path: '/area' },
          { name: "设置不可发货区域", }
        ]
      }
    ]
  },
  {
    path: '/logcofig',
    component: Layout,
    children: [
      {
        path: '/logcof',
        name: 'logcof',
        component: () => import('../views/logcofig/logcof.vue'),
        meta: [
          { name: "物流配置", path: '/logcofig' },
          { name: "物流配置", }
        ]
      }
    ]
  },
  {
    path: '/noregion',
    component: Layout,
    children: [
      {
        path: '/nore',
        name: 'nore',
        component: () => import('../views/noregion/nore.vue'),
        meta: [
          { name: "设置不可发货区域", path: '/noregion' },
          { name: "设置不可发货区域", }
        ]
      }
    ]
  },
  {
    path: '/abolish',
    component: Layout,
    children: [
      {
        path: '/setkd',
        name: 'setkd',
        component: () => import('../views/abolish/setkd.vue'),
        meta: [
          { name: "功能设置", path: '/abolish' },
          { name: "取件设置", }
        ]
      },
      {
        path: '/district',
        name: 'district',
        component: () => import('../views/abolish/district.vue'),
        meta: [
          { name: "功能设置", path: '/abolish' },
          { name: " 不可发货区域管理", }
        ]
      },
      {
        path: '/platform',
        name: 'platform',
        component: () => import('../views/abolish/platform.vue'),
        meta: [
          { name: "功能设置", path: '/abolish' },
          { name: "平台管理", }
        ]
      }, {
        path: '/cause',
        name: 'cause',
        component: () => import('../views/abolish/cause.vue'),
        meta: [
          { name: "功能设置", path: '/abolish' },
          { name: "不可发货原因管理", }
        ]
      }, {
        path: '/collect',
        name: 'collect',
        component: () => import('../views/abolish/collect.vue'),
        meta: [
          { name: "功能设置", path: '/abolish' },
          { name: "物流揽收围栏", }
        ]
      }
    ]
  },
  {
    path: '/coll',
    component: Layout,
    children: [
      {
        path: '/colls',
        name: 'colls',
        component: () => import('../views/coll/index.vue'),
        meta: [
          { name: "邮寄白名单", path: '/colls' },
          { name: "邮寄白名单", }
        ]
      }
    ]
  },
  /*  {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
